* {
    --btn-size: 60px;
  }


.container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.username-input-section {
    margin: auto;
    width: 300px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: #f6f8fa;
    border: 1px solid #d8dee4;
    border-radius: 5px;
    h1 {
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -0.5px;
        text-align: center;
    }
    .username-input {
        padding: 5px 12px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid;
        border-radius: 6px;
        outline: none;
    }
    .username-submit {
        margin-top: 16px;
        background-color: #2c974b;
        padding: 8px 16px;
        text-align: center;
        border: 1px solid;
        border-radius: 6px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
    }
    .username-submit:hover {
        background-color: #288a44;
    }
}

.row {
    display: flex;
    width: 100%;
    max-height: 440px;
}

.video-wrapper {
    position: relative;
    width: 50%;
    height: 80%;
    border: 1px solid 	#7289da;
    .video-player {
        width: 100%;
        height: 100%;
    }
}
.video-wrapper.screen-shared {
    width: 20%;
    position: absolute;
    z-index: 2;
    height: auto;
}
.video-wrapper.self.screen-shared {
    left: 0;
}
.video-wrapper.partner.screen-shared {
    right: 0;
}
.video-wrapper.screen-share-wrapper {
    width: 100%;
    margin: auto;
    height: 100%;
    padding: 10px;
}   


.video-call-controls {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    bottom: 10px;
    background-color: #424549;
    padding: 8px 40px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    .call-controls--btn {
        background-color: transparent;
        border: none;
        color: #c2c4c8;
        svg {
            font-size: 24px;
        }
        cursor: pointer;
    }
}
.video-call-controls.mobile {
    display: none;
}



.call-btn,
.hang-up-btn {
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    svg {
        font-size: 22px;
    }
}

.call-btn {
    background-color: #72bd58;
}

.hang-up-btn {
    background-color: #da0012;
    //position: absolute;
    margin: auto;
}

.btn-wrapper {
    position: relative;
    width: var(--btn-size);
    height: var(--btn-size);
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}
.btn-wrapper.ringing {
    .btn-animation-inner,
    .btn-animation-outer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: none;
        background-color: #91cb7d;
        box-sizing: border-box;
        animation: expand-collapse 1.2s ease 1s infinite ;
    }
    .btn-animation-outer {
        background-color: transparent;
        animation: expand-big 1.2s ease .8s infinite ;
        border: 1px solid #68b84c;
    }
    svg {
        animation: tilt ease 1s infinite ;
    }
}
.btn-wrapper.calling {
    .btn-animation-inner,
    .btn-animation-outer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #68b84c;
        background-color: transparent;
        box-sizing: border-box;
        animation: expand-small 1.2s ease 1s infinite ;
    }
    .btn-animation-outer {
        animation: expand-big 1.2s ease .8s infinite ;
    }
    svg {
        animation: none;
    }
}

.calling-placeholder {
    position: relative;
    width: 50%;
    height: 80%;
    border: 1px solid #424549;
    background-color: #424549;
    display: flex;
    flex-direction: column;
    .btn-wrapper.calling {
        margin: auto;
        width: 70px;
        height: 70px;
        cursor: default;
    }
    .calling-partner-name {
        position: absolute;
        bottom: 20%;
        left: 50%;
        transform: translateX(-50%);
        color: #7289da;
        text-align: center;
    }
    .btn-wrapper.hang-up {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        width: calc(var(--btn-size) * .80);
        height: calc(var(--btn-size) * .80);
    }
}

.incoming-call-section {
    text-align: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    h1 {
        margin-bottom: 40px;
    }
    .accept-reject-call {
        display: flex;
    }
}

.users-list {
    display: flex;
    flex-direction: column;
    width: 30%;
    max-height: 200px;
    overflow-y: scroll;
    margin-left: 20px;
    .user-item {
        margin-bottom: 10px;
        cursor: pointer;
        padding: 5px 0;
    }
}


// responsive
@media screen and (max-width: 800px) {
    .container {
        position: relative;
    }
    .row {
        flex-direction: column;
    }
    .video-wrapper {
        width: 100%;
        .video-call-controls {
            display: none;
        }
    }
    .video-wrapper.self {
        position: absolute;
        z-index: 2;
        right: 20px;
        margin-top: 20px;
        width: 25%;
        height: 20%;
        .video-player {
            width: 100%;
            height: 100%;
        }
    }
    .video-wrapper.partner {
        position: absolute;
        height: 78%;
        width: 100%;
        margin-bottom: 20px;
    }
    .video-call-controls.mobile {
        display: flex;
        position: absolute;
        width: 50%;
        margin-bottom: 80px;
    }
    .row.hang-up-wrapper {
        margin-top: auto;
        margin-bottom: 15px;
    }
    .row.incoming-call-wrapper {
        margin-bottom: 180px;
        margin-top: auto;
    }
    .incoming-call-section {
        width: 70%;
        h1 {
            font-size: 18px;
        }
    }
    .calling-placeholder {
        position: absolute;
        width: 100%;
        height: 78%;
        .btn-wrapper.calling {
            margin: auto;
            width: 80px;
            height: 80px;
            cursor: default;
        }
        .calling-partner-name {
            position: absolute;
            bottom: 30%;
            font-size: 18px;
            width: 80%;
        }
    }
    .row.users-list-wrapper {
        margin-top: 70%;
        max-height: 150px;
        .users-list {
            width: 80%;
            margin: 0 auto;
            margin-top: 10px;
            .user-item {
                margin-bottom: 10px;
                cursor: pointer;
                padding: 5px 0;
            }
        }
    }
}





@keyframes expand-big {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .8
    }
    100% {
        transform: rotate(0) scale(2) skew(1deg);
        opacity: 0
    }
}

@keyframes expand-small {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .8
    }
    100% {
        transform: rotate(0) scale(1.6) skew(1deg);
        opacity: 0
    }
}

@keyframes expand-collapse {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    50% {
        transform: rotate(0) scale(1.5) skew(1deg);
        opacity: .2
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
}

@keyframes tilt {
    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }
}
